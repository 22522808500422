export const SimpleTextEN =
[
  {
    text: 'Welcome to Textilreinigung Asca',
  },
  {
    text: 'We offer:',
  },
  {
    text: '- the cleaning of clothing and home textiles',
  },
  {
    text: '- as well as leather and fur cleaning',
  },
  {
    text: '- and cleaning of carpets.',
  },
  {
    text: 'Opening hours:',
  },
  {
    text: 'Monday - Saturday',
  },
  {
    text: 'from 09h00 - 19h00',
  },
  {
    text: 'You can find us here:',
  },
  {
    "text": "Your reliable partner for professional <strong>cleaning and care</strong> of your textiles. We are a family-run company with <strong>years of experience</strong> and <strong>modern technology</strong>. We offer you a <strong>comprehensive service</strong> for your clothes, curtains, carpets, and upholstered furniture. Whether you want to have your shirts ironed, your suits or evening dresses refreshed, or your down jackets or comforters cleaned - <strong>we are here for you!</strong><br/><br/>At our company, you can rely on <strong>the highest quality</strong>, <strong>fair prices</strong>, and <strong>friendly customer service</strong>. We use <strong>gentle cleaning methods</strong> and <strong>environmentally friendly products</strong> that <strong>protect your textiles</strong> and <strong>extend their lifespan</strong>. We are happy to advise you individually and competently on all questions regarding textile care.<br/><br/>You can find us in the <strong>Industriestraße 7, 97076 Würzburg-Lengfeld</strong>, directly inside the <strong>Kaufland-Supermarkt building</strong>. You can reach us by phone at <strong>0931 271428</strong> or send us a message through our <a href=\"https://textilreinigung-asca.com/contactus\" style=\"color: white\"><strong>contact form</strong></a>.<br/><br/><br/><strong>We look forward to your visit!</strong>"
  }
];
