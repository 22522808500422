import styled, { createGlobalStyle } from 'styled-components'



const GlobalStyle = createGlobalStyle
`
:root
{
 --TestBorder: 2px solid black;


--PrimaryColor: #2a4292ff;
--SecondaryColor: #94a0c8ff;
--ThirdColor: #f3ffffff;
// --PrimaryColor: #fe0000ff;
// --SecondaryColor: #fe7f7fff;
// --ThirdColor: #feededff;


--ContrastColor: white;
--CanvasColor: white;


--Speed: 250ms;
--BorderRadius: 15px;
}


// @font-face
// {
// font-family: "Montserrat";
// src: local("Montserrat"), url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf"), format("truetype");
// }


*
{
box-sizing: border-box;
margin: 0;
padding: 0;
font-family: 'Montserrat';
line-height: 160%;
}




h1
{
  // color: white;
  // color: var(--SecondaryColor);
  color: var(--PrimaryColor);
  font-weight: bold;

  // font-weight: 600;
  // text-stroke-color: var(--SecondaryColor);
  // text-fill-color: white;
  // text-stroke-width: 7px;
  //
  // -moz-text-stroke-color: var(--SecondaryColor);
  // -moz-text-fill-color: white;
  //
  // -webkit-text-stroke-color: var(--SecondaryColor);
  // -webkit-text-fill-color: white;
  //
  // -moz-text-stroke-width: 7px;
  // -webkit-text-stroke-width: 7px;
  //
  // paint-order: stroke fill;
  // -webkit-paint-order: stroke fill;

}

h3
{
  font-weight: 600;
}


`;

export default GlobalStyle
