import React, { useContext } from 'react';
import styled from 'styled-components'


import {ContentContainer, CenterBox} from '../styles/ContentContainer.styles'
import { UserLanguage } from '../scripts/UserLanguage';

import {SimpleText} from '../data/languages/de/Home.data'
import {SimpleTextEN} from '../data/languages/en/Home.data'



const breakwidth = 960

const breakwidth2 = 740

const breakwidth3 = 610

const breakwidth4 = 590

const breakwidth5 = 360




function Impressum()
{

  const {language, setLanguage} = useContext(UserLanguage)


  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Website:</h4>        textilreinigung-asca.com</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Inhaber:</h4>        Asca Wycisk</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Adresse:</h4>        Industriestraße 7, 97076 Würzburg</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>E-Mail:</h4>         textilreinigung.asca@gmail.com</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Telefonnummer:</h4>  0931 / 271428</div>
  return(
    <ImpressumContainer>
      <H1Modified>Datenschutz</H1Modified>

      <TopContentContainer>


        <CenterBoxInfo>
          <div style={{overflow: 'auto'}}>
            <p style={{whiteSpace: 'pre-line'}}>
              <strong>Allgemeine Hinweise</strong>
              <br /><br />
              Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung
              <br /><br /><br />


              <strong>Verantwortlicher für die Datenerfassung auf dieser Website<br /><br /></strong>
              Sebastien Schanen<br />
              Kartause 11<br />
              97070 Würzburg<br />
              E-Mail: schanendesign@gmail.com<br />
              <br /><br /><br />


              <strong>Zugriffsdaten und Hosting<br /><br /></strong>
              <br />
              Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. (...)
              <br /><br /><br />


              <strong>Datenschutz<br /></strong>

              Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.
              <br />
              Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.
              <br />
              Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist im Internet nicht möglich.
              <br /><br /><br />



              <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?<br /><br /></strong>
              <br />
              Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              <br />
              Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.
              <br /><br /><br />


              <strong>Server-Logfiles<br /><br /></strong>
              Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. <br />
              Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes. 
              <br />
              Ihre Daten werden dabei u.a. in die USA übermittelt. Für Datenübermittlungen in die USA liegt ein Angemessenheitsbeschluss der EU-Kommission vor.
              <br /><br /><br />




              <strong>Anfrage per E-Mail, Telefon oder Telefax<br /><br /></strong>
              <br />
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              <br />
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.
              <br />
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
              <br /><br /><br />



              <strong>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars<br /></strong>
              <br />Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme.
              <br />Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
              <br />Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
              <br />Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
              <br /><br /><br />





              <strong>Technisch notwendige Cookies<br /><br /></strong>
              <br />Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
              <br />Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 25 Abs. 2 TTDSG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung der optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.
              <br />Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
              <br /><br /><br /><br />


              
              <strong>Plugins und Tools<br /><br /><br /></strong>
              <strong>Google Web Fonts<br /><br /></strong>

              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.
              Google Maps<br />

              <br />Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
              <br />
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
              <br />
              Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
              <br />
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de.
              <br /><br /><br />



              <strong>Google+ Plugin<br /><br /></strong>
              <br />
              Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
              <br />
              Erfassung und Weitergabe von Informationen: Mithilfe der Google+-Schaltfläche können Sie Informationen weltweit veröffentlichen. Über die Google+-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden.
              <br />
              Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern. Um die Google+-Schaltfläche verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten Namen enthalten muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen.
              <br />
              Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden die von Ihnen bereitgestellten Informationen gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites. Die Verwendung des Google+-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst umfangreichen Sichtbarkeit in den Sozialen Medien.
              <br /><br /><br />




              <strong>Betroffenenrechte und Speicherdauer<br /><br /></strong>

              Dauer der Speicherung<br />
              Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
              <br /><br />
              Rechte der betroffenen Person<br />
              <br />Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.
              <br />Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.
              <br /><br />
              Beschwerderecht bei der Aufsichtsbehörde
              <br />Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
              <br /><br />
              Eine Beschwerde können Sie unter anderem bei der für uns zuständigen Aufsichtsbehörde einlegen, die Sie unter folgenden Kontaktdaten erreichen:
              <br /><br />
              Bayerisches Landesamt für Datenschutzaufsicht (BayLDA)<br />
              <br />Promenade 18
              <br />91522 Ansbach
              <br />Tel.: +49 981 1800930
              <br />Fax: +49 981 180093800
              <br />E-Mail: poststelle@lda.bayern.de
              <br /><br />
              Widerspruchsrecht<br />
              <br />Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu widersprechen.
              <br />Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
              <br /><br />
              Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, können Sie dieser Verarbeitung jederzeit durch Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die Verarbeitung der betroffenen Daten zum Zwecke der Direktwerbung.
              <br /><br />
              letzte Aktualisierung: 10.02.2023
            </p>
          </div>
        </CenterBoxInfo>
      </TopContentContainer>


    </ImpressumContainer>
  )
}


const ImpressumContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 5vh;
text-align: center;
align-items: center;
`


const H1Modified = styled.h1
`
margin-bottom: 9vh;

@media (max-width: ${breakwidth}px)
{
  margin-bottom: 0vh;
}
`



const TopContentContainer = styled(ContentContainer)
`
text-align: center;
justify-content: center;
padding: 2vh 0;
margin: 3vh 0;
`


const TestContainer = styled.div
`
justify-content: center;
display: grid;
grid-template-columns: 40% 40%;
margin:2.5% auto 10%;

@media (max-width: ${breakwidth4}px)
{
  grid-template-columns: 50% 50%;
}
`

const CenterBoxInfo = styled(CenterBox)
`
width: 70vw;
display: inline;
white-space: pre-line;
font-size: 120%;
padding: 2vh 0;

@media (max-width: ${breakwidth}px)
{
  font-size: 15px;
}

@media (max-width: ${breakwidth2}px)
{
  font-size: 12px;
}

@media (max-width: ${breakwidth3}px)
{
  font-size: 10px;
}

@media (max-width: ${breakwidth4}px)
{
  font-size: 10px;
}
@media (max-width: ${breakwidth5}px)
{
  width: 90vw;
}
`

// export const ContentContainer = styled.div
// `
// width: 100%;
// margin: 10vh 0;
// padding: 5vh 0;
// background-color: var(--PrimaryColor);
// color: var(--ContrastColor);
// display: flex;
// justify-content: center;
//
// @media (max-width: ${breakwidth}px)
// {
//   font-size: 10px;
// }
// `


export default Impressum
