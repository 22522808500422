import React, { useContext } from 'react';
import styled from 'styled-components'
import { Helmet } from "react-helmet";


import {ContentContainer, CenterBox, HeroCenterBox} from '../styles/ContentContainer.styles'
import { UserLanguage } from '../scripts/UserLanguage';

import {SimpleText} from '../data/languages/de/Home.data'
import {SimpleTextEN} from '../data/languages/en/Home.data'






const businessSchema = {
      "@context" : "http://schema.org",
      "@type" : "LocalBusiness",
      "address" : {
        "@type": "PostalAddress",
        "addressLocality":"Würzburg",
        "addressCountry": "DE",
        "postalCode":"97076",
        "streetAddress": "Industriestraße 7"
      },
      "description":"Ihr zuverlässiger Partner für die professionelle Reinigung und Pflege Ihrer Textilien!",
      "name":"Textilreinigung Asca",
      "url":"https://textilreinigung-asca.com",
      "image":"https://textilreinigung-asca.com/assets/images/textilreinigung_asca_logo.svg",
      "email":"textilreinigung.asca@gmail.com",
      "telephone":"0931 / 45467776",
      "openingHours": ["Mo 09:00 - 20:00","Tu 09:00 - 20:00","We 09:00 - 20:00","Th 09:00 - 20:00","Fr 09:00 - 20:00"]
};

const breakwidth = 820


// <CenterBox>
//   <IconImage src={process.env.PUBLIC_URL + "./assets/images/clock_icon.svg"}/>
// </CenterBox>


// 0 0 1px red, 0 0 1px red, 0 0 1px red, 0 0 1px red

// -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red, 1px 1px 0 red


function Home()
{

  const {language, setLanguage} = useContext(UserLanguage)


  return(
    <HomeContainer>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(businessSchema)}
        </script>
      </Helmet>


      <h1 style={{margin: 'auto 1rem'}}>{language? SimpleText[0].text:SimpleTextEN[0].text}</h1>


      <TopContentContainer>
          <HeroCenterBox dangerouslySetInnerHTML={{ __html: language? SimpleText[9].text:SimpleTextEN[9].text }}/>
      </TopContentContainer>

      <TopContentContainer>
        <IconImage src={process.env.PUBLIC_URL + "./assets/images/washing_machine_icon.svg"} style={{fill: 'green'}}/>

        <CenterBox>
          <h3>{language? SimpleText[1].text:SimpleTextEN[1].text}</h3>
          <ul>{language? SimpleText[2].text:SimpleTextEN[2].text}</ul>
          <ul>{language? SimpleText[3].text:SimpleTextEN[3].text}</ul>
          <ul>{language? SimpleText[4].text:SimpleTextEN[4].text}</ul>
        </CenterBox>
      </TopContentContainer>

      <TopContentContainer>
        <IconImage src={process.env.PUBLIC_URL + "./assets/images/clock_icon.svg"}/>

        <CenterBox>
          <h3>{language? SimpleText[5].text:SimpleTextEN[5].text}</h3>
          <p>{language? SimpleText[6].text:SimpleTextEN[6].text}</p>
          <p>{language? SimpleText[7].text:SimpleTextEN[7].text}</p>
        </CenterBox>
      </TopContentContainer>

      <ContentContainer>
        <CenterBox>
          <h3 style={{"text-align":"center"}}>{language? SimpleText[8].text:SimpleTextEN[8].text}</h3>

          <Card src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2574.6389700903233!2d9.974715815826736!3d49.81165474106625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a28fba83675d43%3A0x35711429ed4f28fe!2sTextilreinigung%20Asca!5e0!3m2!1sde!2sde!4v1662217249402!5m2!1sde!2sde" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" alt="Textilreinigung Asca" />
        </CenterBox>
      </ContentContainer>
    </HomeContainer>
  )
}


const HomeContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 5vh;
text-align: center;
align-items: center;
`


const Card = styled.iframe
`
border:0;
margin-top:2.5%;
width:75vw;
max-width:1000px;
height:70vh;


@media (max-width: ${breakwidth}px)
{
  margin:2vh 0 2vh%;
  width:80vw;
  height:50vh;
}
`


// const CenteredContainer = styled.div
// `
// margin: auto auto;
// display: flex;
// justify-content: center;
// `


const TopContentContainer = styled(ContentContainer)
`
text-align: left;
justify-content: flex-start;
`


const IconImage = styled.img
`
width: 90px;
margin: 0 30px 0 30vw;


@media (max-width: 1900)
{
  margin-left: 50vw;
}

@media (max-width: 1450)
{
  margin-left: 30vw;
}

@media (max-width: 900px)
{
  margin-left: 10vw;
}
`



export default Home
