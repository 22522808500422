export const SimpleText =
[
  {
    text: 'Kontakt',
  },
  {
    text: 'Sie können uns auch telefonisch erreichen unter:',
  },
  {
    text: 'Kontaktieren Sie uns per E-Mail:',
  },
  {
    text: 'Ihre E-Mail Adresse',
  },
  {
    text: 'Ihr Name',
  },
  {
    text: 'Betreff',
  },
  {
    text: 'Nachricht...',
  },
  {
    text: 'Absenden',
  },
  {
    text: 'E-Mail erfolgreich versendet!',
  },
  {
    text: 'Hier',
  },
  {
    text: ' clicken um zurück auf die Startseite zu gelangen',
  },
];
