import React from 'react';


export const SimpleTextEN =
[
  {
    text: 'Prices',
  },
  {
    text: 'Download the price list:',
  },
  {
    text: 'Download price list',
  },
]




export const TitlesEN =
[
  {
    text: 'CLOTHING',
  },
  {
    text: 'LAUNDRY',
  },
  {
    text: 'HOME TEXTILES',
  },
  {
    text: 'LEATHER CLEANING',
  },
  {
    text: 'CARPET CLEANING',
  },
  {
    text: 'GARDEN UPHOLSTERY',
  },
  {
    text: 'OTHER',
  },
]




export const BekleidungDataEN =
[
  {
    text: 't-shirt',
    price: '3,50',
  },
    {
    text: 'sweater, vest',
    price: '4,50',
  },
  {
    text: 'tie, hat, scarf',
    price: '4,50',
  },
  {
    text: 'jacket, dress*',
    price: '6,50',
  },
    {
    text: 'trousers, blouse',
    price: '6,50',
  },
    {
    text: 'skirt',
    price: '6,50',
  },
  {
    text: 'dress long simple',
    price: '12,50',
  },
  {
    text: 'dirndl',
    price: '8,90',
  },
  {
    text: 'anorak, parka, winter jacket*',
    price: '10,90',
  },
  {
    text: 'coat, tailcoat, robe*',
    price: '12,50',
  },
  {
    text: 'down jacket*',
    price: '12,50',
  },
  {
    text: 'down coat*',
    price: '18,20',
  },
  {
    text: 'overall',
    price: '10,50',
  },
  {
    text: 'bathrobe',
    price: '6,50',
  },
  {
    text: 'long evening dress',
    price: '32,00-44,00',
  },
  {
    text: 'wedding dress',
    price: '120,00',
  },
  {
    text: 'communion dress',
    price: '18,50',
  },
  {
    text: 'communion robes',
    price: '12,50',
  },
  {
    text: 'impregnation',
    price: '4,50',
  },
  {
    text: 'coat impregnation',
    price: '5,30',
  },
  {
    text: 'ironing only (shirt)',
    price: '3,30',
  },
  {
    text: 'shirt washing + ironing',
    price: '4,00',
  },
];


export const FootNotes1DataEN =
[
  {
    text: '*calculation according to the length of the back part',
  },
  {
    text: 'up to 69cm = jacket',
  },
  {
    text: 'up to 89cm = parka/anorak',
  },
  {
    text: 'up to 90cm = coat',
  },
];



    // text: 'Bettwäsche, Tischwäsche              1 Teil',
    // text: '                                                              3 Teile',




export const WäscheDataEN =
[
  {
    text: 'bed linen, table linen             1 part',
    price: '3,00',
  },
  {
      text: '                                                    3 pieces',
      price: '8,70',
  },
  {
    text: '                                                    6 pieces',
    price: '16,80',
  },
  {
    text: '                                                    9 pieces',
    price: '24,30',
  },
  {
    text: '                                                   12 pieces',
    price: '31,20',
  },
  {
    text: 'top shirt',
    price: '3,00',
  },
  {
    text: 'work coat, work trousers',
    price: '3,50',
  },
  {
    text: 'towels, dishtowels',
    price: '1,00',
  },
];





export const HeimtextilienDataEN =
[
  {
    text: 'thick sleeping bag',
    price: '37,50',
  },
  {
    text: 'thin sleeping bag',
    price: '25,00',
  },
  {
    text: 'thick quilts, bedspreads',
    price: '37,50',
  },
  {
    text: 'thin quilts, sommmer bed',
    price: '25,00',
  },
  {
    text: 'wool blankets',
    price: '11,50',
  },
  {
    text: 'rheuma blankets',
    price: '37,50',
  },
  {
    text: 'curtains sqm',
    price: '4,00-4,80',
  },
  {
    text: 'net curtain sqm',
    price: '3,00',
  },
  {
    text: 'feather pillow',
    price: '12,50',
  },
  {
    text: 'footmuff',
    price: '22,80',
  },
];

export const FootNotes2DataEN =
[
  {
    text: 'NOTE: up to 100% surcharge possible for fire and water damage, depending on degree of pollution.',
  },
];







export const LederreinigungDataEN =
[
  {
    text: [<b>Category 1</b>, '\njacket, shirt, trousers, skirt, trousers, dress'],
    price: '49,90',
  },
  {
    text: [<b>Category 2</b>, '\nleather coat, fur coat, fabric coat with inner fur'],
    price: '65,90',
  },
  {
    text: [<b>Category 3</b>, '\nwaistcoat, short trousers, textile goods with 30-50% leather content (jacket, dress, etc.)'],
    price: '36,90',
  },
  {
    text: [<b>Category 4</b>, '\nsmall items such as gloves, binders, winter caps, etc.'],
    price: '18,90',
  },
];







export const TeppichreinigungDataEN =
[
  {
    text: [<b>Price category 1</b>, '\nshort-pile machine carpets (linked-in carpets), patchwork, flokat carpets'],
    price: '17,50 per m²',
  },
  {
    text: [<b>Price category 2</b>, "\nantique oriental, china, nepal, kilim, berber, sisal, handtufted, sheep's wool, woolen woven, long-pile carpets and dirt-trapping mats"],
    price: '21,90 per m²',
  },
  {
    text: [<b>Price category 3</b>, '\nsilk, artificial silk carpet, fur carpet'],
    price: '29,90 per m²',
  },
];







export const GartenpolsterDataEN =
[
  {
    text: 'up to 50cm length / normal width',
    price: '14,90/pc.',
  },
  {
    text: 'up to 80cm length / normal width',
    price: '19,50/pc.',
  },
  {
    text: 'up to 120cm length / normal width',
    price: '21,90/pc.',
  },
  {
    text: 'up to 150cm length / normal width',
    price: '25,90/pc.',
  },
  {
    text: 'up to 200cm length / normal width',
    price: '29,90/pc.',
  },
];







export const SonstigesDataEN =
[
  {
    text: 'couch cover textile/upholstery elements',
    price: 'from 20,00/pc.',
  }
];
