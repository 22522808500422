import React, { useContext } from 'react';
import {Link} from 'react-router-dom'
import styled from 'styled-components'


import {ContentContainer, CenterBox} from '../styles/ContentContainer.styles'
import {DownloadButton} from '../styles/Buttons.styles'
import { UserLanguage } from '../scripts/UserLanguage';


import {SimpleText, Titles, BekleidungData, FootNotes1Data, WäscheData, HeimtextilienData, FootNotes2Data, LederreinigungData, TeppichreinigungData, GartenpolsterData, SonstigesData} from '../data/languages/de/Prices.data'
import {SimpleTextEN, TitlesEN, BekleidungDataEN, FootNotes1DataEN, WäscheDataEN, HeimtextilienDataEN, FootNotes2DataEN, LederreinigungDataEN, TeppichreinigungDataEN, GartenpolsterDataEN, SonstigesDataEN} from '../data/languages/en/Prices.data'






const breakwidth = 700

// onClick={() => setLanguage('en')}


function Template()
{

  function isOdd(num) { return num % 2;}

  const {language, setLanguage} = useContext(UserLanguage)






    return(
    <TemplateContainer>
      <h1>{language? SimpleText[0].text:SimpleTextEN[0].text}</h1>

      <ContentContainer>
        <PriceCenterBox>
          <CenterBoxContainer>{language? SimpleText[1].text:SimpleTextEN[1].text}</CenterBoxContainer>

          <CenterBoxContainer>
            <CenterBoxButtonContainer target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + "./assets/files/Textilreinigung_Asca_Preisliste.pdf"} download="Textilreinigung_Asca_Preisliste.pdf">
              <DownloadButton>{language? SimpleText[2].text:SimpleTextEN[2].text}</DownloadButton>
            </CenterBoxButtonContainer>
          </CenterBoxContainer>
        </PriceCenterBox>
      </ContentContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[0].text:TitlesEN[0].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (BekleidungData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (BekleidungDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))}

        </Services>

        <FootNotes>
          <p>{language? FootNotes1Data[0].text:FootNotes1DataEN[0].text}</p>
          <TabP>{language? FootNotes1Data[1].text:FootNotes1DataEN[1].text}</TabP>
          <TabP>{language? FootNotes1Data[2].text:FootNotes1DataEN[2].text}</TabP>
          <TabP>{language? FootNotes1Data[3].text:FootNotes1DataEN[3].text}</TabP>

        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[1].text:TitlesEN[1].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (WäscheData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText><pre>{el.text}</pre></RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (WäscheDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }

        </Services>

        <FootNotes>
          <p></p>
          <ul></ul>
        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[2].text:TitlesEN[2].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (HeimtextilienData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (HeimtextilienDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }

        </Services>

        <FootNotes>
          <p>{language? FootNotes2Data[0].text:FootNotes2DataEN[0].text}</p>
        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[3].text:TitlesEN[3].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (LederreinigungData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (LederreinigungDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }


        </Services>

        <FootNotes>
          <p></p>
          <ul></ul>
        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[4].text:TitlesEN[4].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (TeppichreinigungData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (TeppichreinigungDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }

        </Services>

        <FootNotes>
          <p></p>
          <ul></ul>
        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[5].text:TitlesEN[5].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (GartenpolsterData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (GartenpolsterDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }

        </Services>

        <FootNotes>
          <p></p>
          <ul></ul>
        </FootNotes>
      </PriceContainer>



      <PriceContainer>
        <TitleBox>
          <Title>{language? Titles[6].text:TitlesEN[6].text}</Title><Title>EUR</Title>
        </TitleBox>

        <Spacer/>

        <Services>

          {language?
            (SonstigesData.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            ))):
            (SonstigesDataEN.map((el, index) => (
              <Row bcolor={isOdd(index)} tcolor={isOdd(index)}>
                <RowText>{el.text}</RowText><RowTextPrice>{el.price}</RowTextPrice>
              </Row>
            )))
          }

        </Services>

        <FootNotes>
          <p></p>
          <ul></ul>
        </FootNotes>
      </PriceContainer>



    </TemplateContainer>
  )
}



const TemplateContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 5vh;
text-align: center;
align-items: center;
`


const PriceContainer = styled.div
`
width: 80vw;
margin: 0 auto;
margin-bottom: 7vh;
max-width: 800px;
`


const TitleBox = styled.div
`
position: absolute;
display: flex;
background-color: var(--PrimaryColor);
width: 80vw;
max-width: 800px;
justify-content: space-between;
z-index: 2;
`


const Title = styled.span
`
margin: 0.1rem 1rem;
color: var(--ContrastColor);
font-weight: 600;

@media (max-width: ${breakwidth}px)
{
  font-size: 14px;
}
`


const Spacer = styled.div
`
height: 25px;

@media (max-width: ${breakwidth}px)
{
  height: 22px;
}
`


const Services = styled.div
`
border: 4px solid var(--SecondaryColor);
width: 80vw:
max-width: 800px;

@media (max-width: ${breakwidth}px)
{
  font-size: 10px;
}
`


const Row = styled.div
`
background-color: ${(props) => props.bcolor ? "var(--SecondaryColor)" : "var(--ContrastColor)"};
color: ${(props) => props.tcolor ? "var(--ContrastColor)" : "black"};
display: grid;
grid-template-columns: 70% 30%;
`


const RowText = styled.span
`
margin: 0 1rem;
text-align: left;
white-space: pre-wrap;
`


const RowTextPrice = styled(RowText)
`
white-space: nowrap;
text-align: right;
`


const FootNotes = styled.div
`
text-align: left;
font-size: 12px;

@media (max-width: ${breakwidth}px)
{
  font-size: 9px;
}
`


const TabP = styled.div
`
margin-left:  1rem
`


const PriceCenterBox = styled(CenterBox)
`
@media (max-width: ${breakwidth}px)
{
  text-align: center;
}
`


const CenterBoxContainer = styled.span
`
font-weight: 600;
font-size: 120%;


@media (max-width: ${breakwidth}px)
{
  display: flex;
  margin: 2vh auto;
  font-size: 180%;
}
`


const CenterBoxButtonContainer = styled.a
`

`




export default Template
