import React from 'react';
import styled from 'styled-components'




function Template()
{
  return(
    <TemplateContainer>
      <h1>Error</h1>
    </TemplateContainer>
  )
}


const TemplateContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 5vh;
text-align: center;
align-items: center;
`


export default Template
