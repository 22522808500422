export const SimpleText =
[
  {
    text: 'Willkommen bei Textilreinigung Asca',
  },
  {
    text: 'Wir bieten:',
  },
  {
    text: '- die Reinigung von Bekleidung und Heimtextilien',
  },
  {
    text: '- sowie Leder- und Pelzreinigung',
  },
  {
    text: '- und die Reinigung von Teppichen.',
  },
  {
    text: 'Öffnungszeiten:',
  },
  {
    text: 'Montag – Samstag',
  },
  {
    text: 'von 09.00 – 19.00 Uhr',
  },
  {
    text: 'Hier können Sie uns finden:',
  },
  {
  text: 'Ihr zuverlässiger Partner für die professionelle <Strong>Reinigung und Pflege Ihrer Textilien</Strong>. Wir sind ein familiengeführtes Unternehmen mit <Strong>langjähriger Erfahrung</Strong> und <Strong>moderner Technik</Strong>. Wir bieten Ihnen einen <Strong>umfassenden Service</Strong> rund um Ihre Kleidung, Gardinen, Teppiche und Polstermöbel. Egal ob Sie Ihre Hemden bügeln, Ihre Anzüge oder Abendkleider auffrischen oder Ihre Daunenjacken oder Bettdecken reinigen lassen wollen - <Strong>wir sind für Sie da!</Strong><br /><br />Bei uns können Sie sich auf <Strong>höchste Qualität</Strong>, <Strong>faire Preise</Strong> und <Strong>freundlichen Kundenservice</Strong> verlassen. Wir verwenden <Strong>schonende Reinigungsverfahren</Strong> und <Strong>umweltfreundliche Produkte</Strong>, die Ihre <Strong>Textilien schützen</Strong> und ihre Lebensdauer verlängern. Wir beraten Sie gerne individuell und kompetent zu allen Fragen der Textilpflege.<br /><br />Sie finden uns in der <Strong>Industriestraße 7, 97076 Würzburg-Lengfeld</Strong>, direkt im Gebäude des <Strong>Kaufland-Supermarkts</Strong>. Sie können uns telefonisch unter <strong>0931 271428</strong> erreichen oder uns eine Nachricht über unser <a href="https://textilreinigung-asca.com/contactus" style="color: white"><Strong>Kontaktformular schicken</Strong></a>.<br /><br /><br /><Strong>Wir freuen uns auf Ihren Besuch!</Strong>'
  }
];
