import React, {useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'


import { UserLanguage } from '../../scripts/UserLanguage';


import {BaseLinks} from '../../data/languages/de/Header_n_Footer.data'
import {BaseLinksEN} from '../../data/languages/en/Header_n_Footer.data'

// {sidebar && <BackdropContainer/>}
// <Link onClick={() => setOpen(!open)}><Icon src={process.env.PUBLIC_URL + "./images/Account.svg"}/></Link>
// <SideNav openSidebar={toggleSidebar}/>
// {sidebar && <SideNav sidebar={sidebar}/>}

// onClick={() => setSidebar(!sidebar)}



const breakwidth = 650


function Footer(props)
{

  const {language, setLanguage} = useContext(UserLanguage)

  return(
    <FooterBlock>
      <Footernormcontent>
        <Contact>
          <FSpace><ContactIcon src={process.env.PUBLIC_URL + "./assets/images/tel_icon.svg"}></ContactIcon></FSpace><FSpace><p>0931 / 271428</p></FSpace>
          <FSpace><ContactIcon src={process.env.PUBLIC_URL + "./assets/images/email_icon.svg"}></ContactIcon></FSpace><FSpace><p>textilreinigung.asca@gmail.com</p></FSpace>
          <FSpace><ContactIcon src={process.env.PUBLIC_URL + "./assets/images/location_icon.svg"}></ContactIcon></FSpace><FSpace><p>Industriestraße 7</p><p>97076 Würzburg</p></FSpace>
        </Contact>

        <FLinks>
          <FSpace><SiteLink to='/' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[0].text:BaseLinksEN[0].text}</SiteLink></FSpace>
          <FSpace><SiteLink to='prices' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[1].text:BaseLinksEN[1].text}</SiteLink></FSpace>
          <FSpace><SiteLink to='contactus' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[2].text:BaseLinksEN[2].text}</SiteLink></FSpace>
          <FSpace><SiteLink to='impressum' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[3].text:BaseLinksEN[3].text}</SiteLink></FSpace>
          <FSpace><SiteLink to='datenschutz' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>DATENSCHUTZ</SiteLink></FSpace>
        </FLinks>
      </Footernormcontent>
    </FooterBlock>
  )
}



const FooterBlock = styled.div
`
background-color:black;
align-content:center;
color:white;
padding:6% 4%;
`


const Footernormcontent = styled.div
`
&:after
{
	content: "";
	display: table;
	clear: both;
}


@media (max-width: ${breakwidth}px)
{
  display: grid;
  grid-template-columns: auto;
}
`


const Contact = styled.div
`
float: left;
width: 50%;
display: grid;
grid-template-columns: 5% auto;


@media (max-width: ${breakwidth}px)
{
  grid-template-columns: auto auto;
}
`


const FLinks = styled.div
`
text-align:right;
float: left;
width: 50%;


@media (max-width: ${breakwidth}px)
{
  text-align: left;
  width: 100%;
  padding: 15vh 15vw 3vh;
}
`


const FSpace = styled.div
`
margin-bottom:3%;


@media (max-width: ${breakwidth}px)
{
  margin-bottom:5%;
}
`


const ContactIcon = styled.img
`
width:50%;


@media (max-width: ${breakwidth}px)
{
  width:5vw;
  margin:0 5vw;
}
`


const SiteLink = styled(Link)
`
&:link
{
	color:white;
	text-decoration: none;
}

&:visited
{
	color:white;
	text-decoration: none;
}

&:hover
{
	color:white;
	text-decoration: none;
	text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

&:active
{
	color:white;
	filter:invert(1);;
	text-decoration: none;
	text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}
`


export default Footer



// <footer>
//   <div class="footernormcontent">
//     <div class="footercontact">
//       <div class="footerspace"><img class="footerspacecontacticon" src="{% static 'images/tel_icon.svg' %}"></div><div class="footerspace"><p>+49 1575 8477851</p></div>
//       <div class="footerspace"><img class="footerspacecontacticon" src="{% static 'images/email_icon.svg' %}"></div><div class="footerspace"><p>boxfit.mainspessart@gmail.com</p></div>
//       <div class="footerspace"><img class="footerspacecontacticon" src="{% static 'images/instagram_icon.svg' %}"></div><div class="footerspace"><p>boxfit_mainspessart</p></div>
//       <div class="footerspace"><img class="footerspacecontacticon" src="{% static 'images/location_icon.svg' %}"></div><div class="footerspace"><p>Würzburger Straße 13</p><p>97753</p><p>Karlstadt</p></div>
//     </div>
//     <div class="footerlinks">
//       <p class="footerspace"><a href="{% url 'home' %}">HOME</a></p>
//       <p class="footerspace"><a href="{% url 'mitgliedwerden' %}">MITGLIED WERDEN</a></p>
//       <p class="footerspace"><a href="{% url 'preise' %}">PREISE</a></p>
//       <p class="footerspace"><a href="{% url 'kontakt' %}">KONTAKT</a></p>
//     </div>
//   </div>
// </footer>



// .footernormcontent
// {
//   display: grid;/**/
//   grid-template-columns: auto;/**/
// }
//
//
// .footercontact
// {
//   float: left;
//   width: 50%;
//   display: grid;
//   grid-template-columns: auto auto;/**/
// }
//
//
// .footerlinks
// {
//   text-align:left;/**/
//   float: left;
//   width: 100%;/**/
//   padding: 7vh 5vw;/**/
// }
//
//
// .footerspace
// {
//   margin-bottom:5%;
// }
//
// .footerspacecontacticon
// {
//   width:5vw;/**/
//   margin:0 5vw;/**/
// }
