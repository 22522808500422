import React, { useContext } from 'react';
import styled from 'styled-components'


import {ContentContainer, CenterBox} from '../styles/ContentContainer.styles'
import { UserLanguage } from '../scripts/UserLanguage';

import {SimpleText} from '../data/languages/de/Home.data'
import {SimpleTextEN} from '../data/languages/en/Home.data'



const breakwidth = 960

const breakwidth2 = 740

const breakwidth3 = 610

const breakwidth4 = 590

const breakwidth5 = 360




function Impressum()
{

  const {language, setLanguage} = useContext(UserLanguage)


  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Website:</h4>        textilreinigung-asca.com</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Inhaber:</h4>        Asca Wycisk</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Adresse:</h4>        Industriestraße 7, 97076 Würzburg</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>E-Mail:</h4>         textilreinigung.asca@gmail.com</div>
  // <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Telefonnummer:</h4>  0931 / 271428</div>
  return(
    <ImpressumContainer>
      <H1Modified>Impressum</H1Modified>

      <TopContentContainer>


        <CenterBoxInfo>
          <TestContainer>
            <div style={{display: 'inline'}}>
              <div style={{whiteSpace: "pre-wrap"}}><h3>Textilreinigung Asca</h3></div><br/>
              <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Website:</h4></div>
              <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Inhaber:</h4></div>
              <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Adresse:</h4></div><br/>
              <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>E-Mail:</h4></div>
              <div style={{whiteSpace: "pre-wrap"}}><h4 style={{display: 'inline'}}>Telefonnummer:</h4></div>
            </div>

            <div style={{display: 'inline'}}>
              <br/><br/>
              <div style={{whiteSpace: "pre-wrap"}}>textilreinigung-asca.com</div>
              <div style={{whiteSpace: "pre-wrap"}}>Asca Wycisk</div>
              <div style={{whiteSpace: "pre-wrap"}}>Industriestraße 7,</div>
              <div style={{whiteSpace: "pre-wrap"}}>97076 Würzburg</div>
              <div style={{whiteSpace: "pre-wrap"}}>textilreinigung.asca@gmail.com</div>
              <div style={{whiteSpace: "pre-wrap"}}>0931 / 271428</div>
            </div>
          </TestContainer>
        </CenterBoxInfo>
      </TopContentContainer>


    </ImpressumContainer>
  )
}


const ImpressumContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 5vh;
text-align: center;
align-items: center;
`


const H1Modified = styled.h1
`
margin-bottom: 9vh;

@media (max-width: ${breakwidth}px)
{
  margin-bottom: 0vh;
}
`



const TopContentContainer = styled(ContentContainer)
`
text-align: center;
justify-content: center;
padding: 2vh 0;
margin: 3vh 0;
`


const TestContainer = styled.div
`
justify-content: center;
display: grid;
grid-template-columns: 40% 40%;
margin:2.5% auto 10%;

@media (max-width: ${breakwidth4}px)
{
  grid-template-columns: 50% 50%;
}
`

const CenterBoxInfo = styled(CenterBox)
`
width: 70vw;
display: inline;
white-space: pre-line;
font-size: 120%;
padding: 2vh 0;

@media (max-width: ${breakwidth}px)
{
  font-size: 15px;
}

@media (max-width: ${breakwidth2}px)
{
  font-size: 12px;
}

@media (max-width: ${breakwidth3}px)
{
  font-size: 10px;
}

@media (max-width: ${breakwidth4}px)
{
  font-size: 10px;
}
@media (max-width: ${breakwidth5}px)
{
  width: 90vw;
}
`

// export const ContentContainer = styled.div
// `
// width: 100%;
// margin: 10vh 0;
// padding: 5vh 0;
// background-color: var(--PrimaryColor);
// color: var(--ContrastColor);
// display: flex;
// justify-content: center;
//
// @media (max-width: ${breakwidth}px)
// {
//   font-size: 10px;
// }
// `


export default Impressum
