export const BaseLinksEN =
[
  {
    text: 'HOME',
  },
  {
    text: 'PRICES',
  },
  {
    text: 'CONTACT',
  },
  {
    text: 'IMPRESSUM',
  },
];
