import React, {useState, useContext} from 'react';
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import axios from 'axios'


import {SendButton} from '../styles/Buttons.styles'
import { UserLanguage } from '../scripts/UserLanguage';


import {SimpleText} from '../data/languages/de/ContactUs.data'
import {SimpleTextEN} from '../data/languages/en/ContactUs.data'


// await axios.post("http://localhost:4000/send_mail",

const breakwidth = 820









function Template()
{

  const {language, setLanguage} = useContext(UserLanguage)



  const [send, setSend] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")


  const handleSend = async(e) => {
    setSend(true)

    try
    {

      await axios.post("https://us-central1-textilreinigung-asca-backend.cloudfunctions.net/emailMessage",
      {
        email: email,
        name: name,
        subject: subject,
        text: text,
      })

    } catch (error) {
      console.log(error)
      console.log(text)
    }
  }




  return(
    <TemplateContainer>
      <h1>{language? SimpleText[0].text:SimpleTextEN[0].text}</h1>

      <div>
        {!send ?
          (
            <KontaktSplit>
              <div>
                <p>{language? SimpleText[1].text:SimpleTextEN[1].text}</p>
                <TelNumber>0931 / 271428</TelNumber>
              </div>

              <div>
                <p style={{'text-align': 'left'}}>{language? SimpleText[2].text:SimpleTextEN[2].text}</p>

                <form onSubmit={handleSend}>
                  <TextBox type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder={language? SimpleText[3].text:SimpleTextEN[3].text} required/>
                  <TextBox type="text" value={name} onChange={(e) => setName(e.target.value)} name="name" placeholder={language? SimpleText[4].text:SimpleTextEN[4].text} required/>
                  <TextBox type="text" value={subject} onChange={(e) => setSubject(e.target.value)} name="betreff" placeholder={language? SimpleText[5].text:SimpleTextEN[5].text} required/>

                  <TextBoxbig type="text" value={text} onChange={(e) => setText(e.target.value)} name="nachricht" placeholder={language? SimpleText[6].text:SimpleTextEN[6].text} wrap="hard" maxlength="1000" required/>
                  <SendingButton><SendButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} type="submit">{language? SimpleText[7].text:SimpleTextEN[7].text}</SendButton></SendingButton>
                </form>
              </div>
            </KontaktSplit>
          ): (
            <ConfirmContainer>
              <ConfirmImage src={process.env.PUBLIC_URL + "./assets/images/checkmark_green.svg"}/>
              <p>{language? SimpleText[8].text:SimpleTextEN[8].text}</p>
              <Link to='/'><button onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? SimpleText[9].text:SimpleTextEN[9].text}</button></Link>{language? SimpleText[10].text:SimpleTextEN[10].text}
            </ConfirmContainer>
          )
        }

      </div>

    </TemplateContainer>
  )
}








const TemplateContainer = styled.div
`
// padding: 6vh 0 5vh;
padding: 25vh 0 7vh;
text-align: center;
align-items: center;
`


const KontaktSplit = styled.div
`
display: grid;
grid-template-columns: 30% 60%;
column-gap:4%;
margin: 6vh 8% 2%;


@media (max-width: ${breakwidth}px)
{
  display: grid;
  grid-template-columns: auto;/**/
  column-gap:4%;
  margin:2.5% 8% 2%;
  row-gap: 3vh;
}
`


const TelNumber = styled.p
`
text-align: center;
`


const TextBox = styled.input
`
width: 100%;
background-color: var(--ThirdColor);
margin-top:3%;
padding:1vh 1vw;
color:grey;
border:2px solid grey;
border-radius:15px;
font-size:80%;


@media (max-width: ${breakwidth}px)
{
  margin-top:1.5vh;
  padding:1vh 3vw;
  border-radius:1.5vw;
  font-size:3vw;
}
`


const TextBoxbig = styled.textarea
`
width: 100%;
background-color: var(--ThirdColor);
padding:1vh 1vw;
color:grey;
border:2px solid grey;
font-size:80%;

border-radius:15px;
margin-top:3vh;
padding-bottom:25vh;
resize: none;
overflow-x: hidden;


@media (max-width: ${breakwidth}px)
{
  padding:1vh 3vw;
  font-size:3vw;

  border-radius:1.75vw;
  padding-bottom:20vh;
}
`


const SendingButton = styled.div
`
text-align:right;
margin-top:4%;


@media (max-width: ${breakwidth}px)
{
  margin-top:2vh;
}
`



const ConfirmContainer = styled.div
`
text-align: center;
`


const ConfirmImage = styled.img
`
margin: 5vh auto;
width: 50vw;
max-width: 400px;

// background-image: url(${process.env.PUBLIC_URL + "./assets/images/checkmark_green.svg"});
// background-repeat: no-repeat;
// background-size: 100%;
// background-position: center center;
`



export default Template






// <main>
//
//   <div class="kontaktsplit">
//     <div>
//       <p>Sie können uns auch telefonisch erreichen unter:</p>
//       <p class="telnumber">+49 1575 8477851</p>
//     </div>
//
//     <div>
//       <p>Kontaktieren Sie uns per E-Mail:</p>
//       <form method="POST" action="{% url 'kontakt' %}">
//         {% csrf_token %}
//         <input class="textbox" type="text" name="email" placeholder="Ihre E-Mail Adresse" required>
//         <input class="textbox" type="text" name="name" placeholder="Ihr Name" required>
//         <input class="textbox" type="textarea" name="betreff" placeholder="Betreff" required>
//         <textarea class="textbox textboxbig" type="text" name="nachricht" placeholder="Nachricht..." wrap="hard" maxlength="1000" required></textarea>
//         <div class="sendingbutton"><button type="submit">Absenden</button></div>
//     </form>
//     </div>
//   </div>
//
//
//
// .kontaktsplit
// {
// 	display: grid;
// 	grid-template-columns: 30% 60%;
// 	column-gap:4%;
// 	margin:2.5% 8% 2%;
// }
//
// .telnumber
// {
// 	text-align:left;
// }
//
// .textbox
// {
// 	width: 100%;
// 	background-color:#fffff6ff;
// 	margin-top:3%;
// 	padding:1vh 1vw;
// 	color:grey;
// 	border:2px solid grey;
// 	border-radius:15px;
// 	font-size:80%
// }
//
// .textboxbig/**/
// {
// 	border-radius:15px;
// 	margin-top:3vh;
// 	padding-bottom:25vh;
// 	resize: none;
// 	overflow-x: hidden;
// }
//
// .sendingbutton
// {
// 	text-align:right;
// 	margin-top:4%;
// }
//
//
//
//
//
//
// .kontaktsplit
// {
//   display: grid;
//   grid-template-columns: auto;/**/
//   column-gap:4%;
//   margin:2.5% 8% 2%;
//   row-gap: 3vh;
// }
//
// .telnumber
// {
//   text-align:center;
// }
//
//
// .textbox/**/
// {
//   width: 100%;
//   background-color:#fffff6ff;
//   margin-top:1.5vh;
//   padding:1vh 3vw;
//   color:grey;
//   border:2px solid grey;
//   border-radius:1.5vw;
//   font-size:3vw;
// }
//
// .textboxbig/**/
// {
//   border-radius:1.75vw;
//   margin-top:3vh;
//   padding-bottom:20vh;
// }
//
// .sendingbutton/**/
// {
//   text-align:right;
//   margin-top:2vh;
// }
