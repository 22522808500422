import React, { useContext } from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'


import {MenuButton} from '../../styles/Buttons.styles'
import { UserLanguage } from '../../scripts/UserLanguage';


import {BaseLinks} from '../../data/languages/de/Header_n_Footer.data'
import {BaseLinksEN} from '../../data/languages/en/Header_n_Footer.data'

// {sidebar && <BackdropContainer/>}
// <Link onClick={() => setOpen(!open)}><Icon src={process.env.PUBLIC_URL + "./images/Account.svg"}/></Link>
// <SideNav openSidebar={toggleSidebar}/>
// {sidebar && <SideNav sidebar={sidebar}/>}

// onClick={() => setSidebar(!sidebar)}




const breakwidth = 820

// <TestCenter>
//   <InputClass type="checkbox" name="" />
// </TestCenter>




function Header(props)
{


  const {language, setLanguage} = useContext(UserLanguage)



  function SetLanguageSwitch()
  {
    setLanguage(!language)
    window.scrollTo({top: 0, behavior: 'smooth'})
  }


  return(
    <HeaderBlock>
      <Link to='/'><LogoImage src={process.env.PUBLIC_URL + "./assets/images/textilreinigung_asca_logo.svg"}/></Link>

      <HeaderLinks>
        <Link to='/'><MenuButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[0].text:BaseLinksEN[0].text}</MenuButton></Link>
        <Link to='prices'><MenuButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[1].text:BaseLinksEN[1].text}</MenuButton></Link>
        <Link to='contactus'><MenuButton onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{language? BaseLinks[2].text:BaseLinksEN[2].text}</MenuButton></Link>
      </HeaderLinks>



      <AltTestCenter>
        <AltInputClass type="checkbox" name=""  onClick={() => SetLanguageSwitch()}/>
      </AltTestCenter>

    </HeaderBlock>
  )
}
// onClick={() => setSidebar(!sidebar)}
// {language?"de":"en"}
const TestCenter = styled.div
`
position: absolute;
top: 11vh;
right: 2vw;
`

const InputClass = styled.input
`
position: relative;
width: 80px;
height: 40px;
-webkit-appearance: none;
background: #c6c6c6;
outline: none;
border-radius: 20px;
box-shadow: inset 0 0 5px rgba(0,0,0,.2);
// transition: all calc(var(--Speed) * 2);
transition: all var(--Speed);


&:checked
{
  background: #03a9f4;
}

&:before
{
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
  // transition: left calc(var(--Speed) * 2);
  transition: all var(--Speed);

}

&:checked:before
{
  left: 40px;
}
`





const AltTestCenter = styled.div
`
position: absolute;
top: 12vh;
right: 2vw;


@media (max-width: ${breakwidth}px)
{
  top: 10vh;
  transform: scale(.8);
}
`

const AltInputClass = styled.input
`
position: relative;
-webkit-appearance: none;
outline: none;
transition: all var(--Speed);

// background: #c6c6c6;
width: 100px;
height: 40px;
box-shadow: inset 0 0 5px rgba(0,0,0,.2);
border-radius: 10px;


background-image: url(${process.env.PUBLIC_URL + "./assets/images/language_switch_background.svg"});
background-repeat: no-repeat;
background-size: 100%;
background-position: center center;

&:checked
{
  // background: #03a9f4;
  background-image: url(${process.env.PUBLIC_URL + "./assets/images/language_switch_background.svg"});
}

&:before
{
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  background: #fff;
  transform: scale(1.1);
  // transition: left calc(var(--Speed) * 2);
  transition: left var(--Speed), border calc(var(--Speed) * 2);

  width: 50px;
  height: 40px;
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
  border-radius: 10px;
  border: 2px solid rgba(0,0,0,0.0);

  background-image: url(${process.env.PUBLIC_URL + "./assets/images/language_switch_icon.svg"});
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center center;

}

&:checked:before
{
  left: 50px;
}

&:hover:before
{
  border: 2px solid var(--SecondaryColor);
}
`




const HeaderBlock = styled.div
`
background-color: var(--PrimaryColor);
position:fixed;
right: 0;
top:0;
left: 0;
margin-right: auto;
margin-left: auto;
min-height: 2em;
width: 100vw;
text-align: right;
font-size:150%;
z-index: 999;
`


const LogoImage = styled.img
`
width: 12vw;
position: absolute;
top: 1vw;
left: 2vw;

@media (max-width: ${breakwidth}px)
{
  width: 150px;
}
`


const HeaderLinks = styled.div
`
margin-right: 5vw;
color: var(--ContrastColor);
padding:1% 0 1%;


@media (max-width: ${breakwidth}px)
{
  margin-right: 2vw;
}
`


const HeaderSpacer = styled.span
`
padding:0 2%;
`

export default Header




// <header id="realheader">
//   <div class="headerlinks">
//     <a href="{% url 'home' %}">HOME</a>                       <span class="headerspacer">|</span>
//     <a href="{% url 'mitgliedwerden' %}">MITGLIED WERDEN</a>  <span class="headerspacer">|</span>
//     <a href="{% url 'preise' %}">PREISE</a>                   <span class="headerspacer">|</span>
//     <a href="{% url 'kontakt' %}">KONTAKT</a>
//   </div>
// </header>



// {
//   background-color:#aa0000ff;
//   position:fixed;
//   right:0;
//   left:0;
//   margin:0 auto;
//   min-height:2em;
//   width:100%;
//   text-align:center;
//   font-size:3vw;
//
//   /* display:inline-block; */
//
//   box-shadow:0 1.5vh 1.5vh #aa0000ff;
// }
