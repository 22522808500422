import React from 'react'
import styled from 'styled-components'





const breakwidth = 820

const breakwidth2 = 570






const BaseButton = styled.button
`
border: 2px solid white;
font-weight: 600;
margin: 2vh 1vw;
padding: 0.25vw 0;
width: 10vw;
color: var(--ContrastColor);
border-radius: 1vw;
cursor: pointer;

&:hover
{
  border: 2px solid black;
}
`

export default BaseButton




export const MenuButton = styled(BaseButton)
`
font-size: 1.2vw;
background-color: var(--SecondaryColor);

@media (max-width: ${breakwidth}px)
{
  width: 100px;
  font-size: 1rem;
}

@media (max-width: ${breakwidth2}px)
{
  width: 60px;
  font-size: 9px;
}
`



export const DownloadButton = styled(BaseButton)
`
background-color: var(--SecondaryColor);
margin: 0 0 0 5vw;
padding: 1vw;
width: 300px;
border-radius: 15px;
font-size: 20px;
`



export const SendButton = styled(BaseButton)
`
background-color: var(--PrimaryColor);
padding: 1vw;
width: 200px;
font-size: 25px;
border-radius: 20px;
`
