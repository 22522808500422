export const SimpleTextEN =
[
  {
    text: 'Contact',
  },
  {
    text: 'You can also reach us by phone at:',
  },
  {
    text: 'Contact us by e-mail:',
  },
  {
    text: 'Your e-mail address',
  },
  {
    text: 'Your Name',
  },
  {
    text: 'Subject',
  },
  {
    text: 'Message...',
  },
  {
    text: 'Send',
  },
  {
    text: 'E-mail sent successfully!',
  },
  {
    text: 'Click here',
  },
  {
    text: ' to go back to the homepage',
  },
];
