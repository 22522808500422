import React, { useState } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import GlobalStyle from './GlobalStyles'


import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf'


import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { UserLanguage } from './scripts/UserLanguage';


//Pages
import Home         from './pages/Home';
import Prices       from './pages/Prices';
import ContactUs    from './pages/ContactUs';

import Impressum    from './pages/Impressum';
import Datenschutz    from './pages/DatenschutzPage';
import ErrorPage    from './pages/ErrorPage';



const asdf = "hello motherfucker"


function App() {

  const [language, setLanguage] = useState(true)


  return (
   <Router>
      <GlobalStyle/>
      <UserLanguage.Provider value={{ language, setLanguage }}>
        <Header/>

        <Routes>

          <Route path='/'             exact element = {<Home/>}         />
          <Route path='/prices'       exact element = {<Prices/>}       />
          <Route path='/contactus'    exact element = {<ContactUs/>}    />

          <Route path='/impressum'    exact element = {<Impressum/>}    />
          <Route path='/datenschutz'    exact element = {<Datenschutz/>}    />

          <Route path='*'             exact element = {<ErrorPage/>}    />


        </Routes>

        <Footer/>
      </UserLanguage.Provider>
    </Router>
  );
}

export default App;
