import React from 'react';


export const SimpleText =
[
  {
    text: 'Preise',
  },
  {
    text: 'Downloaden Sie die Preisliste hier:',
  },
  {
    text: 'Preisliste downloaden',
  },
]




export const Titles =
[
  {
    text: 'BEKLEIDUNG',
  },
  {
    text: 'WÄSCHE',
  },
  {
    text: 'HEIMTEXTILIEN',
  },
  {
    text: 'LEDERREINIGUNG',
  },
  {
    text: 'TEPPICHREINIGUNG',
  },
  {
    text: 'GARTENPOLSTER',
  },
  {
    text: 'SONSTIGES',
  },
]




export const BekleidungData =
[
  {
    text: 'T-Shirt',
    price: '3,50',
  },
  {
    text: 'Pullover, Weste',
    price: '4,50',
  },
  {
    text: 'Krawatte, Mütze, Schal',
    price: '4,50',
  },
  {
    text: 'Jacke, Sakko, Kleid*',
    price: '7,50',
  },
  {
    text: 'Hose, Bluse',
    price: '6,50',
  },
  {
    text: 'Rock',
    price: '6,50',
  },
  {
    text: 'Kleid lang einfach',
    price: '12,50',
  },
  {
    text: 'Dirndl',
    price: '8,90',
  },
  {
    text: 'Anorak, Parka, Winterjacke*',
    price: '10,90',
  },
  {
    text: 'Mantel, Frack, Robe*',
    price: '12,50',
  },
  {
    text: 'Daunenjacke*',
    price: '12,50',
  },
  {
    text: 'Daunenmantel*',
    price: '18,20',
  },
  {
    text: 'Overall',
    price: '10,50',
  },
  {
    text: 'Bademantel',
    price: '6,50',
  },
  {
    text: 'Abendkleid lang',
    price: '32,00-44,00',
  },
  {
    text: 'Brautkleid',
    price: '120,00',
  },
  {
    text: 'Kommunionkleid',
    price: '18,50',
  },
  {
    text: 'Kommunionkutten',
    price: '12,50',
  },
  {
    text: 'Imprägnieren',
    price: '4,50',
  },
  {
    text: 'Mantel imprägnieren',
    price: '5,30',
  },
  {
    text: 'Hemd nur bügeln',
    price: '3,30',
  },
  {
    text: 'Hemd waschen + bügeln',
    price: '4,00',
  },
];


export const FootNotes1Data =
[
  {
    text: '*Berechnung nach Länge des Rückenteils',
  },
  {
    text: 'bis 69cm = Jacke',
  },
  {
    text: 'bis 89cm = Parka/Anorak',
  },
  {
    text: 'bis 90cm = Mantel',
  },
];



    // text: 'Bettwäsche, Tischwäsche              1 Teil',
    // text: '                                                              3 Teile',




export const WäscheData =
[
  {
    text: 'Bettwäsche, Tischwäsche    1 Teil',
    price: '3,00',
  },
  {
    text: '                                                    3 Teile',
    price: '8,70',
  },
  {
    text: '                                                    6 Teile',
    price: '16,80',
  },
  {
    text: '                                                    9 Teile',
    price: '24,30',
  },
  {
    text: '                                                   12 Teile',
    price: '31,20',
  },
  {
    text: 'Oberhemd',
    price: '3,00',
  },
  {
    text: 'Berufsmantel, Berufshose',
    price: '3,50',
  },
  {
    text: 'Handtücher, Geschirrtücher',
    price: '1,00',
  },
];

// No space<br>
// &#8201;Thin space<br>
// &#8194;En space<br>
// &#8195;Em space<br>

// No space<br>
// &thinsp;Thin space<br>
// &ensp;En space<br>
// &emsp;Em space<br>




export const HeimtextilienData =
[
  {
    text: 'Schlafsack dick',
    price: '37,50',
  },
  {
    text: 'Schlafsack dünn',
    price: '25,00',
  },
  {
    text: 'Steppdecken dick, Tagesdecken',
    price: '37,50',
  },
  {
    text: 'Steppdecken dünn, Sommmerbett',
    price: '25,00',
  },
  {
    text: 'Wolldecken',
    price: '11,50',
  },
  {
    text: 'Rheumadecken',
    price: '37,50',
  },
  {
    text: 'Übergardinien qm',
    price: '4,00-4,80',
  },
  {
    text: 'Stores qm',
    price: '3,00',
  },
  {
    text: 'Federkissen',
    price: '12,50',
  },
  {
    text: 'Fußsack',
    price: '22,80',
  },
];

export const FootNotes2Data =
[
  {
    text: 'HINWEIS: Bei Brand- und Wasserschäden bis 100% Aufschlag möglich, je nach Verschmutzungsgrad.',
  },
];







export const LederreinigungData =
[
  {
    text: [<b>Kategorie 1</b>, '\nJacke, Hemd, Hose, Rock, Bundhose, Kleid'],
    price: '49,90',
  },
  {
    text: [<b>Kategorie 2</b>, '\nLedermantel, Pelzmantel, Stoffmantel mit Innenpelz'],
    price: '65,90',
  },
  {
    text: [<b>Kategorie 3</b>, '\nWeste, Hose kurz, Textilware m. 30-50% Lederanteil (Jacke, Kleid usw.)'],
    price: '36,90',
  },
  {
    text: [<b>Kategorie 4</b>, '\nKleinteile wie Handschuhe, Binder, Mützen usw.'],
    price: '18,90',
  },
];







export const TeppichreinigungData =
[
  {
    text: [<b>Preisklasse 1</b>, '\nKurzflormaschinenteppich (eingekettelter Teppichboden), Fleckerl-, Flokat- Teppiche'],
    price: '17,50 je m²',
  },
  {
    text: [<b>Preisklasse 2</b>, '\nAntiker Orient-, China-, Nepal-, Kelim-, Berber-, Sisal-, Handtuft-, Schafwoll-, Wollweb-, Langflor-Teppich u. Schmutzfangmatte'],
    // text: 'Preisklasse 2: \nAntiker Orient-, China-, Nepal-, Kelim-, Berber-, Sisal-, Handtuft-, Schafwoll-, Wollweb-, Langflor-Teppich u. Schmutzfangmatte',
    price: '21,90 je m²',
  },
  {
    text: [<b>Preisklasse 3</b>, '\nSeiden-, Kunstseidenteppich, Fellteppich'],
    // text: '<b>Preisklasse 3</b>: \nSeiden-, Kunstseidenteppich, Fellteppich',
    price: '29,90 je m²',
  },
];







export const GartenpolsterData =
[
  {
    text: 'bis 50cm Länge / normale Breite',
    price: '14,90/Stk.',
  },
  {
    text: 'bis 80cm Länge / normale Breite',
    price: '19,50/Stk.',
  },
  {
    text: 'bis 120cm Länge / normale Breite',
    price: '21,90/Stk.',
  },
  {
    text: 'bis 150cm Länge / normale Breite',
    price: '25,90/Stk.',
  },
  {
    text: 'bis 200cm Länge / normale Breite',
    price: '29,90/Stk.',
  },
];







export const SonstigesData =
[
  {
    text: 'Couchbezug Textil/Polsterelemente',
    price: 'ab 20,00/Stk.',
  },
];
