import React from 'react'
import styled from 'styled-components'




const breakwidth = 820





export const ContentContainer = styled.div
`
width: 100%;
margin: 10vh 0;
padding: 5vh 0;
background-color: var(--PrimaryColor);
color: var(--ContrastColor);
display: flex;
justify-content: center;
`




export const CenterBox = styled.span
`
display: inline-block;
text-align: left;
margin: auto 0;

@media (max-width: ${breakwidth}px)
{
  font-size: 10px;
}
`



export const HeroCenterBox = styled(CenterBox)
`
width: 60vw;
margin: auto auto;


@media (max-width: ${breakwidth}px)
{
  font-size: 16px;
  width: 80vw;
}
`
