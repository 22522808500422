export const BaseLinks =
[
  {
    text: 'HOME',
  },
  {
    text: 'PREISE',
  },
  {
    text: 'KONTAKT',
  },
  {
    text: 'IMPRESSUM',
  },
];
